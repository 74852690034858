@import url("https://fonts.googleapis.com/css2?family=Anton&family=Poppins@family=Archivo:wght@400;500;600&display=swap");

html, body {
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
html {
  max-width: 2100px;
  margin: auto !important;
  box-sizing: border-box;
  overflow-x: hidden;
  position: relative;
  width:100%;
}

@media (max-width: 800px) {
  html {
    max-width: 100vw;
    overflow-x: scroll;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
  font-style: normal;
  max-width: 2100px;
}

h1,
h2 {
  font-family: "Anton";
  font-style: normal;
}

.fade-enter,
.fade-exit {
  position: absolute;
  width: 100%;
}

.fade-enter {
  opacity: 0;
  z-index: 2;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
  z-index: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
